@import ../styles/_vars

$question-card-padding: 25.5px
  
.question 
  position: relative
  transition-duration: .5s
  opacity: 1
  cursor: pointer

  @media (max-width: $md)
    margin-bottom: 1rem

  &:last-of-type
    .question__answer
      @media (min-width: $md)
        transform: translateY(calc(-100% + 2rem))
        top: -1.95rem

    &.question_expanded
      .arrow-button
        transform: rotate(-90deg)

      &_to-top 
        .question__answer
          padding-top: 1rem

  &__title 
    display: flex
    justify-content: space-between
    align-items: center

  &__answer
    position: absolute
    bottom: 0
    right: 0
    background-color: $info-block-color
    transform: translateY(99.5%)
    transition-duration: .5s
    width: 100%
    overflow: hidden
    height: 0

    > .body-text
      padding: 0 1.8rem
      font-size: 1.3rem

      @media (max-width: $md)
        font-size: 1rem

  &_hidden
    opacity: 0

  &_expanded    
    .arrow-button
      transform: rotate(90deg)

    .question__answer
      padding-top: 1rem
      height: 136%

      @media (max-width: $md)
        padding-top: 0
        height: 117%


  &:hover
    .arrow-button
      background-color: $accent-transparent

.question__button
  width: 48px
  width: 48px
  border-radius: 50%
  border: 1px solid white
  
  img
    width: 16px
    height: 10px

